import DatePicker from 'vue2-datepicker';
import searchRequest from '../searchRequest';

export default {
  name: 'requestGroupDetails',
  components: {
    searchRequest,
    DatePicker
  },
  data() {
    return {
      loader: false,
      respTableIndex: 0,
      showRequestForm: false,
      showRequestGroupModal: false,
      showAlert: false,
      editMode: true,
      requestGroupDetails: null,
      data: [
        {
          active: false,
          request_group_mpng_id: null,
          request_id: null,
          request_name: null,
          request_type: null
        }
      ],
      requestGroupForm: {
        report_group_id: 0,
        report_group_name: null,
        creation_date: null,
        created_by: null,
        request_details: null
      },
      fields: [
        {
          key: 'request_group_mpng_id',
          class: 'd-none'
        },
        {
          key: 'request_id',
          class: 'd-none'
        },
        {
          key: 'request_name',
          label: 'Request Name'
        },
        {
          key: 'request_type',
          label: 'Request Type'
        },
        {
          key: 'active',
          label: 'Active'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ]
    };
  },
  mounted() {
    this.eventBus.$on('requestFormHeader', obj => {
      this.editMode = false;
      this.requestGroupForm.report_group_id = obj.report_group_id;
      this.requestGroupForm.report_group_name = obj.report_group_name;
      this.requestGroupForm.creation_date = obj.creation_date;
      this.requestGroupForm.created_by = obj.created_by;
      this.getRequestGroupHeader(obj.report_group_id);
    });
    this.eventBus.$on('respFormActions', actionName => {
      if (actionName === 'edit' || actionName === 'update') {
        this.editMode = true;
        this.update = true;
      }
      if (actionName === 'add') {
        this.editMode = true;
        this.requestGroupForm.report_group_id = null;
      }
      if (actionName === 'save' && this.editMode) {
        this.addEditReportGroup();
      }
      if (actionName === 'upload') {
        this.eventBus.$emit('commonUpload', {
          id: this.requestGroupForm.report_group_id
        });
      }
    });
  },
  methods: {
    getRequestGroupHeader(reportGroupId) {
      this.loader = true;
      this.$store
        .dispatch('requestGroup/getRequestGroupByIdList', reportGroupId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const detail = response.data.data.request_group_details;
            if (detail && detail.length > 0) {
              this.data = response.data.data.request_group_details;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    resetModal() {},
    addNewRow() {
      if (this.editMode) {
        this.data.push({
          active: false,
          request_group_mpng_id: 0,
          request_id: null,
          request_name: null,
          request_type: null
        });
      }
    },
    removeRow(index) {
      if (this.editMode || this.dtlEditMode) {
        this.data.splice(index, 1);
      }
    },
    addEditReportGroup() {
      const detail = this.data.map(dtlObj => {
        return {
          request_id: dtlObj.request_id,
          active:  dtlObj.active
        };
      });
      const payload = {
        report_group_id: this.requestGroupForm.report_group_id,
        report_group_name: this.requestGroupForm.report_group_name,
        request_details: detail ? detail : null
      };
      this.loader = true;
      this.$store
        .dispatch('requestGroup/addRequestGroupList', payload)
        .then(response => {
          this.loader = false;
          this.$emit('updateRequestGroupList');
          this.showAlert = true;
          if (response.status === 201) {
            this.requestGroupForm.report_group_id =
              response.data.data.report_group_id;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.editMode = false;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openRequestForm(index) {
      if (this.editMode) {
        this.respTableIndex = index;
        this.showRequestForm = true;
      }
    },
    selectedSearchRequest(reqData) {
      this.data[this.respTableIndex].request_name = reqData.request_name;
      this.data[this.respTableIndex].request_id = reqData.request_id;
      this.data[this.respTableIndex].request_type = reqData.request_type;
      this.data[this.respTableIndex].active = reqData.active;
      this.showRequestForm = false;
    }
  },
  destroyed() {
    this.eventBus.$off('requestFormHeader');
    this.eventBus.$off('respFormActions');
  }
};
