import commonHelper from '@/app/utility/common.helper.utility';
import requestGroupDetails from './requestGroupDetails';
export default {
  name: 'requestGroup',
  components: {
    requestGroupDetails
  },
  watch: {
    currentPage: function() {
      this.getRequestGroups();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getRequestGroups();
    }
  },

  data() {
    return {
      unsubscribe: null,
      loader: false,
      showRequestForm: false,
      requestGroupDetails: null,
      currentPage: 1,
      payload: {},
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      showRequestGroupModal: false,
      totalRows: null,
      name: '',
      data: [],
      fields: [
        {
          key: 'report_group_id',
          class: 'd-none'
        },
        {
          key: 'report_group_name',
          label: 'Request Group Name'
        },
        {
          key: 'created_by',
          label: 'Created By'
        },
        {
          key: 'creation_date',
          label: 'Creation Date'
        }
      ]
    };
  },
  mounted() {
    this.getRequestGroups();

    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (this.showRequestGroupModal) {
          this.eventBus.$emit('respFormActions', actionName);
        }
        if (actionName === 'add') {
          this.showRequestGroupModal = true;
        }
        if (actionName === 'download' && !this.showRequestGroupModal) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'requestGroup/getRequestGroupList',
            'request-Group',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getRequestGroups() {
      this.payload = {
        report_group_name: this.name,
        _page: this.currentPage - 1,
        _limit: this.perPage
      };
      this.loader = true;
      this.$store
        .dispatch('requestGroup/getRequestGroupList', this.payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.data = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clearRequestGroups() {
      this.name = null;
    },
    updateRequestGroupList() {
      this.getRequestGroups();
    },
    resetModal() {},
    rowSelected(rowData) {
      this.showRequestGroupModal = true;
      setTimeout(() => {
        this.eventBus.$emit('requestFormHeader', rowData);
      }, 0);
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
